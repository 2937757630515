
@use '@angular/material' as mat;
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@include mat.core();
$Beon-primary: mat.define-palette(mat.$indigo-palette);
$Beon-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$Beon-warn: mat.define-palette(mat.$red-palette);
$Beon-theme: mat.define-light-theme((
  color: (
    primary: $Beon-primary,
    accent: $Beon-accent,
    warn: $Beon-warn,
  )
));
@include mat.all-component-themes($Beon-theme);


@font-face {
  font-family: "sf-pro-display";
  src: url("./assets/fonts/SFPRODISPLAYMEDIUM.OTF");
  font-display: block;
}

*{

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "GHEA Grapalat", sans-serif;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

.mat-dialog-container{
  //width: auto !important;
  padding: 0px !important;
  overflow: hidden !important;
  width: auto;
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}
.mat-dialog-content{
  margin: 0 !important;
}



.call-dialog-container{
 background: transparent;
 box-shadow: none;
 .mat-dialog-container{
  background: transparent;
  box-shadow: none;
 }
}

.call-dialog-container-desktop{
  position: relative !important;
}

.p-toast-message-warn {
  background-color: #f5d2f6 !important;
  border: solid #c99cbf !important;
  color: #450358 !important;
  .p-toast-message-icon {
      color: #450358 !important;
  }
  .p-toast-icon-close {
      color: #450358 !important;
  }
}

